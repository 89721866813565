<template>
  <section>
    <img style="vertical-align: middle;height: auto;width: 100%;" src="/img/polideli.png" width="1920" height="1080">
    <div class="row" style="padding: 1em;">
      <div class="col-12">
        <span style="font-weight:bolder;">เงื่อนไขโปรโมชั่น: </span> เงื่อนไขเป็นไปตามที่บริษัทฯกำหนด บริษัทฯขอสงวนสิทธิในการเปลี่ยนแปลงเงื่อนไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: "FooterDeliverPolicy"
}
</script>

<style>

</style>